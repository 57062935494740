import { render, staticRenderFns } from "./SequencePage.vue?vue&type=template&id=4bb2068a&scoped=true&"
import script from "./SequencePage.vue?vue&type=script&lang=ts&"
export * from "./SequencePage.vue?vue&type=script&lang=ts&"
import style0 from "./SequencePage.vue?vue&type=style&index=0&id=4bb2068a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb2068a",
  null
  
)

export default component.exports